<template>
  <div class="bg-default">
    <header class="pa-2">
      <div
        class="cover d-flex justify-space-between _bg pa-4 rounded"
        style="height: 150px"
      >
        <section class="_half">
          <img
            :src="avatar ? avatar : fotoDummy"
            height="150px"
            width="150px"
            class="_circle _min"
            alt="avatar"
          />
        </section>
        <section class="d-flex align-end">
          <router-link to="/edit-profile" style="text-decoration: none">
            <v-btn color="indigo" dark>
              <v-icon dark small> mdi-pencil </v-icon>
              Edit profile
            </v-btn>
          </router-link>
        </section>
      </div>
      <div v-if="loadProfile">
        <v-dialog v-model="loadProfile" hide-overlay persistent width="250">
          <v-card class="indigo" dark>
            <v-card-text>
              silahkan tunggu...
              <v-progress-linear
                indeterminate
                color="white"
                class="mb-0"
              ></v-progress-linear>
            </v-card-text>
          </v-card>
        </v-dialog>
      </div>
      <div class="pa-4 d-flex" style="height: 100px" v-if="!loadProfile">
        <section style="width: 150px"></section>
        <section class="px-5">
          <h3 class="font-weight-bold pa-0 ma-0">{{ profile.nama_lengkap }}</h3>
          <section class="d-flex">
            <small class="grey--text pa-0 mr-2">{{ profile.name }}</small>
            <v-divider vertical></v-divider
            ><small class="grey--text pa-0 ml-2">{{ profile.email }}</small>
          </section>
        </section>
      </div>
    </header>
    <section class="_bout">
      <v-card elevation="0" color="#f4f6f9" v-if="bio != null">
        <v-toolbar color="grey lighten-2" flat height="40px" class="mb-3">
          <span class="text-center">Biodata</span>
        </v-toolbar>
        <div
          class="d-flex flex-wrap mb-2 white rounded"
          v-for="(val, key) in bio"
          :key="val.id"
        >
          <section class="_key px-3 grey lighten-2 rounded">
            {{ key }}
          </section>
          <section class="_val px-3">{{ val }}</section>
        </div>
      </v-card>
    </section>
  </div>
</template>

<script>
import { GET_USER } from "../../../graphql/graphql";
export default {
  name: "Profile",
  apollo: {
    profile: {
      query: GET_USER,
      error(error) {
        return error;
      }
    }
  },

  watch: {
    profile(val) {
      if (!this.loadProfile) {
        let bio = {};
        // console.log("key");
        for (let key in val.pembimbing) {
          // console.log(key);
          if (key !== "id" && key !== "user_id" && key !== "__typename") {
            bio[key.replace(/_/g, " ")] = val.pembimbing[key]
              ? val.pembimbing[key]
              : "";
          }
        }
        // console.log(bio);
        this.bio = bio;
      }
    }
  },

  computed: {
    fotoDummy() {
      const pp = "https://dashboard.anargya.id/img/fotoprofile.jpg";
      return pp;
    },
    avatar() {
      let pp = null;
      if (this.profile) {
        if (this.profile.data_avatar) {
          pp = `${process.env.VUE_APP_GRAPHQL}/avatar/${this.profile.data_avatar.file}`;
        }
      }
      return pp;
    },
    loadProfile() {
      return this.$apollo.queries.profile.loading;
    }
  },
  data() {
    return {
      profile: null,
      bio: null,
      password: "",
      passwordConfirm: "",
      show: false,
      show2: false
    };
  },
  methods: {}
};
</script>

<style scoped>
._half {
  width: 50%;
}
._circle {
  border-radius: 50%;
}
img {
  object-fit: cover;
}

._bg {
  background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),
    url("../../../assets/img/cover.jpg");
  background-size: cover;
  background-position: center;
}
._bg_ {
  background: salmon;
  background-size: cover;
  background-position: center;
}
._min {
  margin-bottom: -200px;
}
._bout {
  /* background: #fff; */
  width: 50%;
  margin: 10px;
  padding: 10px;
  border-radius: 8px;
  height: max-content;
}
._key {
  width: 30%;
  /* background: salmon; */
}
._val {
  width: 70%;
  /* background: red; */
}
._fas-costume {
  font-size: 40px;
}
.temp-fas {
  border-radius: 5px;
  margin: 5px;
  padding: 10px;
}
.temp-fas:hover {
  background: gold;
}
</style>
