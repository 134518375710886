<template>
  <div class="bg-default">
    <header class="pa-2">
      <div
        class="cover d-flex justify-space-between _bg pa-4 rounded"
        style="height: 150px"
      >
        <section class="_half">
          <img
            :src="avatar ? avatar : fotoDummy"
            height="150px"
            width="150px"
            class="_circle _min"
            alt="avatar"
          />
        </section>
        <section
          v-if="precentage == 100"
          class="_half d-flex justify-end align-end"
        >
          <router-link to="/edit-profile" style="text-decoration: none">
            <v-btn color="indigo" dark>
              <v-icon dark small> mdi-pencil </v-icon>
              Edit profile
            </v-btn>
          </router-link>
        </section>
      </div>
      <div v-if="loadProfile">
        <v-dialog v-model="loadProfile" hide-overlay persistent width="250">
          <v-card class="indigo" dark>
            <v-card-text>
              silahkan tunggu...
              <v-progress-linear
                indeterminate
                color="white"
                class="mb-0"
              ></v-progress-linear>
            </v-card-text>
          </v-card>
        </v-dialog>
      </div>
      <div class="pa-4 d-flex" style="height: 100px" v-if="!loadProfile">
        <section style="width: 150px"></section>
        <section class="px-5">
          <h3 class="font-weight-bold pa-0 ma-0">{{ profile.nama_lengkap }}</h3>
          <section class="d-flex">
            <small class="grey--text pa-0 mr-2">{{ profile.name }}</small>
            <v-divider vertical></v-divider
            ><small class="grey--text pa-0 ml-2">{{ profile.email }}</small>
          </section>
        </section>
      </div>
    </header>
    <div class="pa-3 d-flex">
      <section class="_bout">
        <div class="d-flex justify-end"></div>
        <div class="mb-2 d-flex">
          <v-progress-linear
            v-model="precentage"
            rounded
            color="light-green accent-4"
            height="27"
            class="mb-2"
          >
            <small
              ><b>Kelengkapan profile : {{ precentage }}%</b></small
            >
          </v-progress-linear>
          <router-link
            v-if="precentage < 100"
            to="/update-profile"
            style="text-decoration: none"
            ><v-btn small class="ml-2" color="indigo" dark
              >Lengkapi profil</v-btn
            ></router-link
          >
        </div>
        <div v-if="bio != null">
          <div
            class="d-flex flex-wrap mb-2 white rounded"
            v-for="(val, key) in bio"
            :key="val.id"
          >
            <section class="_key px-3 grey lighten-2 rounded">
              {{ key }}
            </section>
            <section class="_val px-3">{{ val }}</section>
          </div>
        </div>
                <v-card class="mt-4 white rounded">
          <v-toolbar
            color="indigo"
            class="d-flex align-center justify-center"
            dense
          >
            <v-icon color="grey">mdi-form-textbox-password</v-icon>
            <h3 class="ml-1 white--text text-center">Ganti kata sandi</h3>
          </v-toolbar>
          <section class="px-7">
            <v-row>
              <v-col cols="12" md="5" class="d-flex align-center">
                Kata sandi
              </v-col>
              <v-col cols="12" md="7">
                <v-text-field
                  label="Kata sandi"
                  required
                  v-model="password"
                  :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="show ? 'text' : 'password'"
                  :error-messages="PasswordError"
                  @input="$v.password.$touch()"
                  @blur="$v.password.$touch()"
                  @click:append="show = !show"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="5" class="d-flex align-center"
                >Konfirmasi kata sandi</v-col
              >
              <v-col cols="12" md="7">
                <v-text-field
                  label="Konfirmasi sandi"
                  required
                  v-model="passwordConfirm"
                  :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="show2 ? 'text' : 'password'"
                  @click:append="show2 = !show2"
                  :error-messages="PasswordConfirmError"
                  @input="$v.passwordConfirm.$touch()"
                  @blur="$v.passwordConfirm.$touch()"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" md="5"></v-col>
              <v-col cols="12" md="7">
                <v-btn color="indigo" dark @click="updatePeserta"
                  >Ubah kata sandi</v-btn
                >
              </v-col>
            </v-row>
          </section>
        </v-card>
      </section>
      <section class="_bout mt-5">
        <v-card class="white rounded">
          <v-toolbar
            color="indigo"
            dense
            class="d-flex align-center justify-center"
            v-if="!loadProfile"
          >
            <!-- <v-icon color="yellow accent-4" large
              >mdi-currency-usd-circle-outline</v-icon
            > -->
            <!-- <h3 class="ml-1 white--text">{{ profile.student.poin }} Poin</h3> -->
            <h3 class="white--text">Badges</h3>
          </v-toolbar>
          <section
            class="d-flex justify-center mt-2 pa-3 flex-wrap"
            v-if="profile.data_badges.length"
          >
            <div
              class="temp-fas"
              v-for="items in profile.data_badges"
              :key="items.id"
            >
              <v-tooltip top max-width="200px" nudge-top="5px">
                <template v-slot:activator="{ on, attrs }">
                  <img
                    :src="`${env}/badges/${items.badge.file}`"
                    height="100px"
                    v-bind="attrs"
                    v-on="on"
                  />
                </template>
                <span>{{ items.badge.keterangan }}</span>
              </v-tooltip>
            </div>
          </section>
        </v-card>
        <v-card class="mt-3">
          <v-toolbar class="indigo" dark dense>
            <h3 class="text-center">Informasi Poin</h3>
          </v-toolbar>
          <section class="pa-3">
            <v-row>
            <v-col cols="12" md="6">Total Poin</v-col>
            <v-col cols="12" md="6">
              {{ totalPoin.data }}
              <v-icon color="yellow accent-4"
                >mdi-currency-usd-circle-outline</v-icon
              ></v-col
            >
          </v-row>
          <v-row>
            <v-col cols="12" md="6">Poin Tersisa</v-col>
            <v-col cols="12" md="6"
              >{{ profile.student.poin }}
              <v-icon color="yellow accent-4"
                >mdi-currency-usd-circle-outline</v-icon
              ></v-col
            >
          </v-row>
          </section>
        </v-card>
        <v-card class="white rounded mt-4">
          <v-toolbar
            color="indigo"
            dense
            class="d-flex align-center justify-center"
            v-if="!loadProfile"
          >
            <v-icon color="yellow accent-4" large>mdi-clock</v-icon>
            <h3 class="ml-1 white--text">History transksi poin</h3>
          </v-toolbar>
          <section>
            <v-data-table
              :loading="loadTable"
              :headers="headers"
              :items="point.data"
              hide-default-footer
              :page.sync="page"
              :items-per-page="limit"
            >
              <template v-slot:[`item.tipe`]="{ item }">
                <div v-if="item.tipe == 'IN'">
                  <p>Poin Masuk</p>
                </div>
                <div v-if="item.tipe == 'OUT'">
                  <p>Poin Keluar</p>
                </div>
              </template>
            </v-data-table>
          </section>
        </v-card>
      </section>
    </div>
  </div>
</template>

<script>
import {
  GET_USER,
  // ALL_BADGE,
} from "../../../graphql/graphql";
import { mapState } from "vuex";
import { EDIT_STUDENT, HISTORY_POINT } from "../../../graphql/Student";
import Swal from "sweetalert2";
import { validationMixin } from "vuelidate";
import { required, sameAs, minLength } from "vuelidate/lib/validators";

export default {
  name: "Profile",
  apollo: {
    profile: {
      query: GET_USER,
      error(error) {
        return error;
      },
    },
    point: {
      query: HISTORY_POINT,
      variables() {
        return {
          page: this.page,
          first: this.limit,
          user_id: parseInt(localStorage.getItem("id")),
        };
      },
      error(err) {
        console.log(err);
      },
    },
  },
  mixins: [validationMixin],
  validations: {
    password: { required, minLength: minLength(8) },
    passwordConfirm: { required, sameAsPassword: sameAs("password") },
  },
  watch: {
    profile(val) {
      if (!this.loadProfile) {
        let bio = {};
        // console.log("key");
        for (let key in val.student) {
          // console.log(key);
          if (
            key !== "id" &&
            key !== "poin" &&
            key !== "user_id" &&
            key !== "config" &&
            key !== "__typename"
          ) {
            bio[key.replace(/_/g, " ")] = val.student[key]
              ? val.student[key]
              : "";
          }
        }
        // console.log(bio);
        this.bio = bio;
      }
    },
  },
  mounted() {
    console.log(this.allBadge);
    this.$store.dispatch("getTotalPoin").then((data) => {
      console.log(data);
    });
  },
  computed: {
    ...mapState(["precentage", "badges", "totalPoin"]),
    loadTable() {
      return this.$apollo.queries.point.loading;
    },
    paginate() {
      let page = null;
      if (this.allMentor.paginatorInfo) {
        let total = this.allMentor.paginatorInfo.total;
        page = Math.ceil(total / this.limit);
      }
      return page;
    },
    fotoDummy() {
      const pp = "https://dashboard.anargya.id/img/fotoprofile.jpg";
      return pp;
    },
    avatar() {
      let pp = null;
      if (this.profile) {
        if (this.profile.data_avatar) {
          pp = `${process.env.VUE_APP_GRAPHQL}/avatar/${this.profile.data_avatar.file}`;
        }
      }
      return pp;
    },
    loadProfile() {
      return this.$apollo.queries.profile.loading;
    },
    PasswordError() {
      const errors = [];
      if (!this.$v.password.$dirty) return errors;
      !this.$v.password.minLength &&
        errors.push("Kata sandi minimal 8 karakter!");
      !this.$v.password.required && errors.push("Kata sandi harus di isi!");
      return errors;
    },
    PasswordConfirmError() {
      const errors = [];
      if (!this.$v.passwordConfirm.$dirty) return errors;
      !this.$v.passwordConfirm.sameAsPassword &&
        errors.push("kata sandi tidak sama!");
      !this.$v.passwordConfirm.required &&
        errors.push("Kata sandi harus di isi!");
      return errors;
    },
  },
  data() {
    return {
      profile: null,
      bio: null,
      password: "",
      passwordConfirm: "",
      show: false,
      show2: false,
      allBadge: null,
      env: process.env.VUE_APP_GRAPHQL,
      point: null,
      page: 1,
      limit: 10,
      headers: [
        { text: "id", value: "id" },
        { text: "Jumlah", value: "jumlah" },
        {
          text: "Keterangan",
          align: "start",
          sortable: false,
          value: "keterangan",
        },
        { text: "Tipe", value: "tipe" },
      ],
    };
  },
  methods: {
    updatePeserta() {
      this.$v.password.$touch();
      this.$v.passwordConfirm.$touch();
      const id = parseInt(this.profile.id);
      const id_profile = parseInt(this.profile.student.id);
      const tipe_avatar = this.profile.tipe_avatar;
      // if (!this.$v.password.required && this.$v.passwordConfirm.required){

      if (this.passwordConfirm == this.password) {
        this.$apollo
          .mutate({
            mutation: EDIT_STUDENT,
            variables: {
              id: id,
              connect: id,
              id_profile: id_profile,
              password: this.password,
              tipe_avatar: tipe_avatar,
            },
          })
          .then(() => {
            Swal.fire({
              icon: "success",
              title: "Profile telah terupdate",
              allowOutsideClick: false,
              confirmButtonColor: "#3F51B5",
              confirmButtonText: `Silakan login kembali`,
            }).then((result) => {
              if (result.isConfirmed) {
                localStorage.clear();
                this.$router.go("login");
              }
            });
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
        Swal.fire({
          icon: "error",
          title: "Password tidak sama",
          allowOutsideClick: false,
          confirmButtonColor: "#3F51B5",
          confirmButtonText: `Ya`,
        });
      }
      // }else {
      //   console.log('manuk')
      // }
    },
  },
};
</script>

<style scoped>
._half {
  width: 50%;
}
._circle {
  border-radius: 50%;
}
img {
  object-fit: cover;
}
.bg-default {
  background: #f4f6f9;
}
._bg {
  background: linear-gradient(rgba(0, 0, 0, 0.3), rgba(0, 0, 0, 0.3)),
    url("../../../assets/img/cover.jpg");
  background-size: cover;
  background-position: center;
}
._bg_ {
  background: salmon;
  background-size: cover;
  background-position: center;
}
._min {
  margin-bottom: -200px;
}
._bout {
  /* background: #fff; */
  width: 50%;
  margin: 10px;
  padding: 10px;
  border-radius: 8px;
  height: max-content;
}
._key {
  width: 30%;
  /* background: salmon; */
}
._val {
  width: 70%;
  /* background: red; */
}
._fas-costume {
  font-size: 40px;
}
.temp-fas {
  border-radius: 5px;
  margin: 5px;
  padding: 10px;
}
.temp-fas:hover {
  background: gold;
}
.v-tooltip__content {
  background-color: black !important;
}
</style>
