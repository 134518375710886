<template>
  <div class="_bg-default">
    <ProfileStudent v-if="role == 4" />
    <ProfileMentor v-if="role == 3" />
    <ProfileTeacher v-if="role == 2" />
    <ProfileAdmin v-if="role == 1" />
  </div>
</template>

<script>
import ProfileStudent from "../../components/General/profile/profileStudent";
import ProfileMentor from "../../components/General/profile/profileMentor";
import ProfileTeacher from "../../components/General/profile/profileTeacher";
import ProfileAdmin from "../../components/General/profile/profileAdmin";
export default {
  name: "profilePage",
  components: {
    ProfileStudent,
    ProfileMentor,
    ProfileTeacher,
    ProfileAdmin
  },
  data() {
    return {
      role: localStorage.getItem("role_id")
    };
  }
};
</script>

<style>
._bg-default {
  background: #f4f6f9;
  min-height: 90vh;
}
</style>
